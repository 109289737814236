import React, { useContext, useEffect } from "react";
import MuiMarkdown from "mui-markdown";

import AdvisorContainer from "../layout/AdvisorContainer";

import markdownPath from "./UserGuide.md";
import { AppContext } from "../AppRouter";

const UserGuide = () => {
    const { config } = useContext(AppContext);
    const [markdown, setMarkdown] = React.useState("");

    // we could use webpack to load the resource instead
    useEffect(() => {
        fetch(markdownPath)
            .then(response => response.text())
            .then(setMarkdown);
    }, []);

    return (
        <AdvisorContainer title={config.i18n.page.user_guide}>
            <MuiMarkdown>{markdown}</MuiMarkdown>
        </AdvisorContainer>
    );
};

export default UserGuide;

import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import { Dimension, Document } from "../client";
import { parseDimensionFiltersCustomizationParameters } from "../util/parameters";
import HierarchyAutoCompleteBox from "./picker/HierarchyAutoCompleteBox";
import DimensionBox from "./DimensionBox";
import DimensionAutoCompleteBox from "./picker/DimensionAutoCompleteBox";
import { AppContext } from "../AppRouter";

const DimensionFilters = ({ dimensionParameters, customizationParameters, metadata, disabled, onUpdate }) => {
    const { client, config } = useContext(AppContext);

    const dispatchDimensionSearch = (dimension, queryString, page = 0, dimsToIgnore = []) => {
        const dims = { ...dimensionParameters };
        dimsToIgnore.forEach((toIgnore) => {
            delete dims[toIgnore];
        });

        return client.dimension.dimensionSearch(dimension, {
            documents: [Document.PURCHASE_ORDER],
            dimension_parameters: dims,
        },
        queryString, page, void 0);
    };

    const filterMap = {
        DIMENSION: (param) => {
            const elementsDimOrder = [Dimension.SPEND_GROUP, Dimension.CLIENT, Dimension.CLIENT_COUNTRY, Dimension.VENDOR, Dimension.VENDOR_TYPE,
                Dimension.VENDOR_SEGMENT, Dimension.PROJECT, Dimension.OWNER, Dimension.INTRA_GROUP, Dimension.SITE, Dimension.COMPANY, Dimension.MATERIAL,
                Dimension.MATERIAL_GROUP, Dimension.VENDOR_COUNTRY];

            return elementsDimOrder.map((dimension) => {
                if (param && param.includes(dimension)) {
                    const values = dimensionParameters[dimension] || [];
                    return (
                        <DimensionAutoCompleteBox
                            key={dimension + "-box"}
                            disabled={disabled}
                            dimension={dimension}
                            onChange={(dimValues) => {
                                const newDimensionParameters = { ...dimensionParameters };
                                let newMetadata = { ...metadata };

                                // always clear the old values.
                                delete newDimensionParameters[dimension];
                                if (dimValues?.length) {
                                    newDimensionParameters[dimension] = dimValues.map(dim => dim.id);

                                    newMetadata = dimValues.reduce((acc, value) => {
                                        return { ...acc, [value.id]: value };
                                    }, newMetadata);
                                }

                                onUpdate(newDimensionParameters, newMetadata);
                            }}
                            onQuery={(queryString, page = 0) => dispatchDimensionSearch(dimension, queryString, page)}
                            metadata={metadata}
                            values={values}
                            sx={{ m: 0.5, width: 350 }}
                        />
                    );
                } else {
                    return null;
                }
            });
        },

        HIERARCHY: (param) => {
            const elementsDimOrder = [Dimension.ORG_UNIT, Dimension.PURCHASE_CATEGORY];

            return elementsDimOrder.map((dimension) => {
                if (param && param.includes(dimension)) {
                    const values = config.hierarchy[dimension].map(dim => dimensionParameters[dim] || []);

                    return (
                        <DimensionBox
                            title={config.i18n.dimension[dimension]}
                            key={dimension + "-box"}
                        >
                            <HierarchyAutoCompleteBox
                                disabled={disabled}
                                dimensions={config.hierarchy[dimension]}
                                values={values}
                                metadata={metadata}
                                onChange={(dims, vals, metadata) => {
                                    const newDimensionParameters = { ...dimensionParameters };

                                    // need to set all dimension of the hierarchy.
                                    dims.forEach((dim, idx) => {
                                        // always clear the old values.
                                        delete newDimensionParameters[dim];
                                        if (vals[idx] && vals[idx].length) {
                                            newDimensionParameters[dim] = vals[idx];
                                        }
                                    });

                                    onUpdate(newDimensionParameters, metadata);
                                }}
                                onQuery={(dim, queryString, dimsToIgnore, page = 0) =>
                                    dispatchDimensionSearch(dim, queryString, page, dimsToIgnore)}
                            />
                        </DimensionBox>
                    );
                } else {
                    return null;
                }
            });
        },
    };

    const elementsByType = parseDimensionFiltersCustomizationParameters(customizationParameters);

    if (!elementsByType.DIMENSION && !elementsByType.HIERARCHY) {
        return null;
    }

    //
    // TODO this component must be split into two (one for dimensions and another for hierarchy) for responsiveness
    //

    return (
        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center" }}>
            {
                elementsByType.DIMENSION
                    ? (
                        <DimensionBox title={config.i18n.customization_bar.parties}>
                            {
                            filterMap["DIMENSION"](elementsByType["DIMENSION"])
                        }
                        </DimensionBox>
                        )
                    : null
            }
            {
                filterMap["HIERARCHY"](elementsByType["HIERARCHY"])
            }
        </Box>
    );
};

DimensionFilters.propTypes = {
    dimensionParameters: PropTypes.object,
    customizationParameters: PropTypes.array,
    metadata: PropTypes.object,
    disabled: PropTypes.bool,
    onUpdate: PropTypes.func,
};

DimensionFilters.defaultProps = {
    dimensionParameters: {},
    customizationParameters: [],
    metadata: {},
    disabled: false,
};

export default DimensionFilters;

"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Method = void 0;
/**
 * A method that determines the scenario start value
 */
var Method;
(function (Method) {
    Method["AVG_1"] = "AVG_1";
    Method["AVG_3"] = "AVG_3";
    Method["AVG_6"] = "AVG_6";
    Method["AVG_12"] = "AVG_12";
    Method["MOVING_AVG_3"] = "MOVING_AVG_3";
    Method["MOVING_AVG_6"] = "MOVING_AVG_6";
    Method["MOVING_AVG_12"] = "MOVING_AVG_12";
    Method["MOVING_MED_3"] = "MOVING_MED_3";
    Method["MOVING_MED_6"] = "MOVING_MED_6";
    Method["MOVING_MED_12"] = "MOVING_MED_12";
    Method["HOMOLOGOUS_12"] = "HOMOLOGOUS_12";
    Method["HOMOLOGOUS_24"] = "HOMOLOGOUS_24";
    Method["HOMOLOGOUS_36"] = "HOMOLOGOUS_36";
    Method["HOMOLOGOUS_AVG_24"] = "HOMOLOGOUS_AVG_24";
    Method["HOMOLOGOUS_AVG_36"] = "HOMOLOGOUS_AVG_36";
    Method["LOOKUP"] = "LOOKUP";
})(Method || (exports.Method = Method = {}));

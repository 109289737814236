import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatDateTime, formatYearMonthDay, formatYearMonthLong } from "../../util/formatter";
import AdvisorContainer from "../../layout/AdvisorContainer";
import ClickableDataGrid from "../../components/ClickableDataGrid";
import { AppContext } from "../../AppRouter";

const Collector = () => {
    const { client, config, notify } = useContext(AppContext);
    const navigate = useNavigate();

    const [collectors, setCollectors] = useState();

    const columns = [
        {
            field: "name",
            headerName: config.i18n.collector.name,
            flex: 6,
            sortable: false,
        },
        {
            field: "start_date",
            headerName: config.i18n.collector.start_date,
            flex: 2,
            sortable: false,
            renderCell: params => `${formatYearMonthLong(params.value, config.locale?.language)}`,
        },
        {
            field: "end_date",
            headerName: config.i18n.collector.end_date,
            flex: 2,
            sortable: false,
            renderCell: params => `${formatYearMonthLong(params.value, config.locale?.language)}`,
        },
        {
            field: "cutoff_date",
            headerName: config.i18n.collector.cutoff_date,
            flex: 2,
            sortable: false,
            renderCell: params => `${formatYearMonthLong(params.value, config.locale?.language)}`,
        },
        {
            field: "created_by_name",
            headerName: config.i18n.collector.created_by,
            flex: 2,
            sortable: false,
        },
        {
            field: "created_at",
            headerName: config.i18n.collector.created_at,
            flex: 2,
            sortable: false,
            renderCell: (params) => {
                return params.value
                    ? (
                        <span title={formatDateTime(params.value, config.locale?.language, config.locale?.timezone)}>
                            {formatYearMonthDay(params.value, config.locale?.language)}
                        </span>
                        )
                    : null;
            },
        },
    ];

    useEffect(() => {
        client.collector.collectorListCollectors()
            .then((collectors) => {
                setCollectors(collectors.response);
            })
            .catch((error) => {
                setCollectors(null);
                notify.error(error, "collector.fetch");
            });
    }, []);

    return (
        <AdvisorContainer
            title={config.i18n.collector.list.title}
            loading={!collectors}
            loadingLabel={config.i18n.collector.loading}
        >
            <ClickableDataGrid
                rows={collectors}
                columns={columns}
                onRowClick={params => navigate(`/collector/${params.id}`)}
            />
        </AdvisorContainer>
    );
};

export default Collector;

import PropTypes from "prop-types";
import React, { useContext } from "react";
import { isEmpty, union, without } from "lodash";

import { parseCustomizationParameters } from "../util/parameters";
import DimensionBox from "./DimensionBox";
import { convertToISODate, hasValue } from "../util/util";
import CustomParameterSelector from "./CustomParameterSelector";
import DimensionSelector from "./selector/DimensionSelector";
import DateSelector from "./selector/DateSelector";
import CommonSelector from "./selector/CommonSelector";
import CurrencySelector from "./selector/CurrencySelector";
import { Stack } from "@mui/system";
import { AppContext } from "../AppRouter";

const CustomizationBar = ({
    customizationParameters,
    analysisParameters,
    analysisFiltersInDimensions,
    analysisFiltersInParameters,
    onAnalysisFiltersInUpdate,
    analysisFiltersOutDimensions,
    analysisFiltersOutParameters,
    onAnalysisFiltersOutUpdate,
    analysisCustomParameters,
    analysisChart,
    analysisHideInsights,
    statisticalLines,
    analysisMetadata,
    onParameterUpdate,
    onChartUpdate,
    onHideInsightsUpdate,
    onCustomParameterUpdate,
    onStatisticalLinesUpdate,
    disabled,
}) => {
    const { config } = useContext(AppContext);

    function getCommonSelector(chartParam, limitParam, hideTailParam, ignoreNullParam, statLinesParam, hideInsightsParam) {
        if (!chartParam && !limitParam && !hideTailParam && !ignoreNullParam && !statLinesParam) {
            return null;
        }

        let chart = null;
        if (chartParam) {
            chart = {
                options: chartParam.options,
                value: analysisChart,
                onUpdate: onChartUpdate,
            };
        }

        let limit = null;
        if (limitParam) {
            limit = {
                options: limitParam.options,
                value: analysisParameters["LIMIT"],
                onUpdate: value => onParameterUpdate({ LIMIT: value }),
            };
        }

        let hideTail = null;
        if (hideTailParam) {
            hideTail = {
                value: analysisParameters["HIDE_TAIL"],
                onUpdate: value => onParameterUpdate({ HIDE_TAIL: value }),
            };
        }

        let ignoreNull = null;
        if (ignoreNullParam) {
            ignoreNull = {
                value: analysisParameters["IGNORE_NULL"],
                onUpdate: value => onParameterUpdate({ IGNORE_NULL: value }),
            };
        }

        let statLines = null;
        if (statLinesParam) {
            statLines = {
                options: statLinesParam.options,
                value: statisticalLines,
                onUpdate: onStatisticalLinesUpdate,
            };
        }

        let hideInsights = null;
        if (hideInsightsParam) {
            hideInsights = {
                value: analysisHideInsights,
                onUpdate: onHideInsightsUpdate,
            };
        }

        return (
            <CommonSelector
                key="common-selector"
                disabled={disabled}
                chart={chart}
                limit={limit}
                tail={hideTail}
                ignoreNull={ignoreNull}
                statLines={statLines}
                hideInsights={hideInsights}
            />
        );
    }

    function getDateSelector(dateParam, dateAggParam, annualizeParam, cumulativeParam, scenarioParam) {
        if (!dateParam) {
            return null;
        }

        const date = analysisParameters["DATE"];

        let dateAgg = null;
        if (dateAggParam) {
            const dateAggValue = analysisParameters["DATE_AGG"];
            dateAgg = {
                options: dateAggParam.options,
                value: hasValue(dateAggValue) ? dateAggValue : dateAggParam.default,
                onUpdate: (value) => {
                    if (["DATE_YEAR_MONTH", "DATE_YEAR_QUARTER"].includes(value)) {
                        onParameterUpdate({ DATE_AGG: value, ANNUALIZE: false });
                    } else {
                        onParameterUpdate({ DATE_AGG: value });
                    }
                },
            };
        }

        let annualize = null;
        if (annualizeParam) {
            // It is always possible to annualize, except when time aggregation parameter exists, and it aggregates to month or quarter
            const annualizeValue = analysisParameters["ANNUALIZE"];
            annualize = {
                disabled: ["DATE_YEAR_MONTH", "DATE_YEAR_QUARTER"].includes(analysisParameters["DATE_AGG"]),
                value: hasValue(annualizeValue) ? annualizeValue : annualizeParam.default,
                onUpdate: value => onParameterUpdate({ ANNUALIZE: value }),
            };
        }

        let cumulative = null;
        if (cumulativeParam) {
            cumulative = {
                value: analysisParameters["CUMULATIVE"],
                onUpdate: value => onParameterUpdate({ CUMULATIVE: value }),
            };
        }

        let scenario = null;
        if (scenarioParam) {
            scenario = {
                options: scenarioParam.options,
                selected: analysisParameters["SCENARIO"],
                cutoff_date: hasValue(analysisParameters["CUTOFF_DATE"]) ? analysisParameters["CUTOFF_DATE"] : scenarioParam.cutoff_date,
            };
        }

        return (
            <DateSelector
                key="date-selector"
                disabled={disabled}
                date={date}
                dateAgg={dateAgg}
                annualize={annualize}
                cumulative={cumulative}
                scenario={scenario}
                onUpdate={(minDate, maxDate, scenario, cutoffDate) =>
                    onParameterUpdate({ DATE: [convertToISODate(minDate), convertToISODate(maxDate)], SCENARIO: scenario, CUTOFF_DATE: cutoffDate })}
            />
        );
    }

    function getCurrencySelector(forexDateParam, dateParam) {
        if (!dateParam || !forexDateParam) {
            return null;
        }

        const date = analysisParameters["DATE"];

        let forexDate = null;
        if (forexDateParam) {
            forexDate = analysisParameters["FOREX_DATE"];
        }

        return (
            <CurrencySelector
                key="currency-selector"
                disabled={disabled}
                forexDate={forexDate}
                datePickers={date}
                onUpdate={value => onParameterUpdate({ FOREX_DATE: value !== null ? convertToISODate(value) : null })}
            />
        );
    }

    function getDimensionSelector(analysisDimensions, analysisDimParameters, onDimensionUpdate, title, key) {
        return (
            <DimensionSelector
                dataCyProp={`${key}-dim-selector`}
                title={title}
                disabled={disabled}
                documentDimensions={elementsByType.DIMENSION}
                dimensions={analysisDimensions}
                dimensionFilters={analysisDimParameters}
                metadata={analysisMetadata}
                parameters={analysisParameters}
                onUpdate={(dimension, dimensionFilters = null, metadata = null) => {
                    const newDimensions = union(analysisDimensions, [dimension]);

                    const newAnalysisDimParameters = { ...analysisDimParameters };

                    // remove entry on dimension parameters, if the value is empty.
                    if (dimensionFilters !== null) {
                        Object.entries(dimensionFilters).forEach(([key, value]) => {
                            if (isEmpty(value)) {
                                delete newAnalysisDimParameters[key];
                            } else {
                                newAnalysisDimParameters[key] = value;
                            }
                        });
                    }

                    onDimensionUpdate(newDimensions, newAnalysisDimParameters, metadata);
                }}
                onDelete={(dimension, dimensionKeys) => {
                    const newDimensions = without(analysisDimensions, dimension);
                    [dimension, ...dimensionKeys].forEach(key => delete analysisDimParameters[key]);

                    onDimensionUpdate(newDimensions, analysisDimParameters);
                }}
            />
        );
    }

    const elementsByType = parseCustomizationParameters(customizationParameters);
    const final = [
        getCommonSelector(elementsByType.CHART, elementsByType.LIMIT, elementsByType.HIDE_TAIL, elementsByType.IGNORE_NULL, elementsByType.STAT_LINES,
            elementsByType.HIDE_INSIGHTS),
        getDateSelector(elementsByType.DATE, elementsByType.DATE_AGG, elementsByType.ANNUALIZE, elementsByType.CUMULATIVE, elementsByType.SCENARIO),
        getCurrencySelector(elementsByType.FOREX_DATE, elementsByType.DATE),
        getDimensionSelector(analysisFiltersInDimensions, analysisFiltersInParameters, onAnalysisFiltersInUpdate,
            config.i18n.customization_bar.filters_in, "filters-in"),
        getDimensionSelector(analysisFiltersOutDimensions, analysisFiltersOutParameters, onAnalysisFiltersOutUpdate,
            config.i18n.customization_bar.filters_out, "filters-out"),
    ];

    if (elementsByType["CUSTOM"]) {
        final.push(
            <DimensionBox
                dataCyProp="custom_parameters_box"
                title={config.i18n.custom_parameters.title}
                key="user-box"
                collapsible
                defaultClosed
            >
                <CustomParameterSelector
                    definition={elementsByType["CUSTOM"]}
                    values={analysisCustomParameters}
                    disabled={disabled}
                    onCustomParameterUpdate={onCustomParameterUpdate}
                />
            </DimensionBox>,
        );
    }

    return (
        <Stack
            marginTop={2}
            px={1}
            spacing={1}
            datacy="customization_bar"
        >
            {final}
        </Stack>
    );
};

CustomizationBar.propTypes = {
    customizationParameters: PropTypes.array,
    analysisParameters: PropTypes.object,
    analysisFiltersInDimensions: PropTypes.array,
    analysisFiltersInParameters: PropTypes.object,
    onAnalysisFiltersInUpdate: PropTypes.func,
    analysisFiltersOutDimensions: PropTypes.array,
    analysisFiltersOutParameters: PropTypes.object,
    onAnalysisFiltersOutUpdate: PropTypes.func,
    analysisCustomParameters: PropTypes.object,
    analysisChart: PropTypes.string,
    analysisHideInsights: PropTypes.string,
    statisticalLines: PropTypes.array,
    analysisMetadata: PropTypes.object,
    onParameterUpdate: PropTypes.func,
    onChartUpdate: PropTypes.func,
    onHideInsightsUpdate: PropTypes.func,
    onStatisticalLinesUpdate: PropTypes.func,
    onCustomParameterUpdate: PropTypes.func,
    disabled: PropTypes.bool,
};

export default CustomizationBar;

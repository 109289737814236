"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Document = void 0;
/**
 * An enumeration.
 */
var Document;
(function (Document) {
    Document["PURCHASE_BUDGET"] = "PURCHASE_BUDGET";
    Document["SALES_BUDGET"] = "SALES_BUDGET";
    Document["PURCHASE_REQUISITION"] = "PURCHASE_REQUISITION";
    Document["PURCHASE_ORDER"] = "PURCHASE_ORDER";
    Document["PURCHASE_INVOICE"] = "PURCHASE_INVOICE";
    Document["SALES_INVOICE"] = "SALES_INVOICE";
    Document["PURCHASE_SETTLEMENT"] = "PURCHASE_SETTLEMENT";
    Document["PURCHASE_RECEIPT"] = "PURCHASE_RECEIPT";
    Document["LEDGER"] = "LEDGER";
    Document["LEDGER_INVOICE"] = "LEDGER_INVOICE";
    Document["LEDGER_BUDGET"] = "LEDGER_BUDGET";
    Document["LEDGER_ORDER"] = "LEDGER_ORDER";
    Document["STOCK_EMPLOYEE"] = "STOCK_EMPLOYEE";
    Document["STOCK_CLIENT"] = "STOCK_CLIENT";
    Document["STOCK_CLIENT_BUDGET"] = "STOCK_CLIENT_BUDGET";
    Document["STOCK_STORE_AREA"] = "STOCK_STORE_AREA";
})(Document || (exports.Document = Document = {}));

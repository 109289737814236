import React from "react";
import { styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";

const StyledClickableDataGrid = styled(DataGrid)(({ responsive }) => ({
    ".MuiDataGrid-row": {
        cursor: "pointer",
    },
    ".MuiDataGrid-cell:focus-within, .MuiDataGrid-cell:focus": {
        outline: "none",
    },
    ".MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-columnHeader:focus": {
        outline: "none",
    },
    ".MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
        ".MuiDataGrid-columnHeaderTitleContainerContent": {
            display: "none",
        },
    },
    "maxHeight": responsive ? "calc(80vh)" : "490px",
    ".MuiDataGrid-virtualScroller": {
        overflowX: "hidden",
    },

    ".non-clickable-row": {
        pointerEvents: "none",
        cursor: "default",
    },
}));

const ClickableDataGrid = ({ rows, columns, rowHeight = 38, pageSize = 10, onRowClick, getRowClassName, isRowSelectable, hideFooterPagination, responsive = false, dataCy }) => {
    return (
        <StyledClickableDataGrid
            data-cy={dataCy}
            rows={rows}
            columns={columns}
            rowHeight={rowHeight}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
            onRowClick={onRowClick}
            getRowClassName={getRowClassName}
            isRowSelectable={isRowSelectable}
            initialState={{
                pagination: {
                    paginationModel: { pageSize: pageSize, page: 0 },
                },
            }}
            pageSizeOptions={[pageSize]}
            hideFooterPagination={hideFooterPagination}
            responsive={responsive}
        />
    );
};

ClickableDataGrid.propTypes = {
    rows: PropTypes.array,
    columns: PropTypes.array,
    rowHeight: PropTypes.number,
    pageSize: PropTypes.number,
    onRowClick: PropTypes.func,
    getRowClassName: PropTypes.func,
    isRowSelectable: PropTypes.func,
    hideFooterPagination: PropTypes.bool,
    responsive: PropTypes.bool,
    dataCy: PropTypes.string,
};

export default ClickableDataGrid;

import React, { useContext, useEffect, useState } from "react";
import AdvisorContainer from "../../layout/AdvisorContainer";
import dayjs from "dayjs";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
    formatYearMonth,
    getAllMonthsBetweenDates,
    getNumberOfDecimalsToShow,
} from "../../util/formatter";
import { Alert, Button, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { computeOperator } from "../../util/math";
import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../../AppRouter";
import { isEmpty } from "lodash";
import { hasValue } from "../../util/util";

const CollectorEntryItemsEdit = () => {
    const { client, config, notify } = useContext(AppContext);

    const [entry, setEntry] = useState(null);
    const [collector, setCollector] = useState(null);
    const [dataActual, setDataActual] = useState(null);
    const [dataReference, setDataReference] = useState(null);
    const [dataDelta, setDataDelta] = useState(null);
    const [allItems, setAllItems] = useState(null);
    const [rows, setRows] = useState(null);
    const [changed, setChanged] = useState(false);
    const [extEntriesNames, setExtEntriesNames] = useState(null);

    const { collectorId, entryId } = useParams();
    const navigate = useNavigate();

    const formatter = (value, isPercentage) => {
        const numberOfDecimals = getNumberOfDecimalsToShow(value);

        if (isPercentage) {
            return new Intl.NumberFormat(config.locale.language, {
                style: "percent",
                minimumFractionDigits: numberOfDecimals,
                maximumFractionDigits: numberOfDecimals,
            });
        }

        return new Intl.NumberFormat(config.locale.language, {
            currencySign: "accounting",
            currencyDisplay: "code",
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
        });
    };

    const isPowerUser = (permission) => {
        return permission?.UPDATE;
    };

    const isNormalUserEditing = () => {
        return !isPowerUser(entry?.permission) && entry?.status !== "IN_REVIEW" && entry?.status !== "APPROVED";
    };

    const isEditableCell = (readOnly, permission, date, cutoffDate, block) => {
        return !readOnly && (isPowerUser(permission) || (permission?.UPDATE_FUTURE && date >= cutoffDate && block === "ACTUAL"));
    };

    const getCellColor = (isEditable, block, date, cutoffDate) => {
        if (!isEditable || block === "DELTA") {
            return "transparent";
        } else if (date < cutoffDate) {
            return "#e0fcbc";
        } else if (date >= cutoffDate) {
            return "#fffccc";
        }
    };

    const getRowName = (cell) => {
        return {
            type: "text",
            text: cell?.name,
            nonEditable: true,
            style: {
                fontWeight: cell.text_style,
                paddingLeft: cell.indentation * 20 + 5,
                alignItems: "flex-end",
            },
        };
    };

    const getRowCellValue = (cell, readOnly, permission, collector, val, key, isPercentage) => {
        const isEditable = isEditableCell(readOnly, permission, key, collector.cutoff_date, cell.block);

        return {
            type: "number",
            id: cell.id, // needed to match with allItems
            value: val,
            column: key,
            isPercentage: isPercentage,
            nonEditable: !isEditable,
            format: formatter(val, isPercentage),
            style: {
                fontWeight: cell.text_style,
                background: getCellColor(isEditable, cell.block, key, collector.cutoff_date),
                color: !isEditable ? "grey" : "black",
                alignItems: "flex-end",
            },
        };
    };

    const getRowTotal = (cell, values, isPercentage) => {
        const total = computeOperator(values, isPercentage ? "AVERAGE" : "SUM");

        return {
            id: "total",
            type: "number",
            value: total,
            block: cell.block,
            column: "total",
            isPercentage: isPercentage,
            nonEditable: true,
            format: formatter(total, isPercentage),
            style: {
                fontWeight: cell.text_style,
                color: "grey",
                alignItems: "flex-end",
            },
        };
    };

    const getRowCells = (cell, index, readOnly, items, collector, permission) => {
        const values = []; // needed for total computations

        return {
            rowId: index,
            cells: [
                getRowName(cell),
                ...Object.entries(cell?.values).map(([key]) => {
                    const val = items.find(row => row.id === cell.id)?.values[key];

                    return getRowCellValue(cell, readOnly, permission, collector, val, key, cell.is_percentage);
                }),
                getRowTotal(cell, values, cell.is_percentage),
            ],
        };
    };

    const handleCellEdit = (changes) => {
        let newAllItems = allItems;

        for (let i = 0; i < changes.length; i++) { // multiple cells can be changed at once, for example, when pasting multiple values
            const newCell = changes[i].newCell;

            newAllItems = newAllItems.map((row) => {
                if (row.id === newCell.id) {
                    if (isNaN(newCell.value)) {
                        newCell.value = null; // dataGrid component interprets the deletion of a value as becoming NaN
                    }
                    return {
                        ...row,
                        values: {
                            ...row.values,
                            [newCell.column]: newCell.value,
                        },
                    };
                }
                return row;
            });
        }

        setAllItems(newAllItems);
        setChanged(true);
    };

    const saveCollectorEntry = () => {
        const dataToUpdate = dataActual.concat(dataReference).flatMap((row) => {
            return row.cells.filter(item => item.type === "number" && item.column !== "total").map((item) => {
                return {
                    id: item.id,
                    value: item.value,
                    date: item.column,
                };
            });
        });

        client.collector.collectorUpdateCollectorEntryItems(entryId, dataToUpdate)
            .then((collectorEntry) => {
                setEntry(collectorEntry);
                setChanged(false);
            })
            .catch((error) => {
                notify.error(error, "collector.entry.update");
            });
    };

    const submitForReviewCollectorEntry = () => {
        client.collector.collectorSubmitForReviewCollectorEntry(entryId)
            .then(() => {
                navigate(`/collector/${collectorId}`);
            })
            .catch((error) => {
                notify.error(error, "collector.entry.submit_for_review");
            });
    };

    const approveCollectorEntry = () => {
        client.collector.collectorApproveCollectorEntry(entryId)
            .then(() => {
                navigate(`/collector/${collectorId}`);
            })
            .catch((error) => {
                notify.error(error, "collector.entry.approve");
            });
    };

    const rejectCollectorEntry = () => {
        client.collector.collectorRejectCollectorEntry(entryId)
            .then(() => {
                navigate(`/collector/${collectorId}`);
            })
            .catch((error) => {
                notify.error(error, "collector.entry.reject");
            });
    };

    const updateBlock = (block, calculatedValuesBlock = block) => {
        return block.map((row) => {
            const values = []; // needed for total computations
            return {
                ...row,
                cells: row?.cells.map((cell) => {
                    if (cell.column === "total") {
                        const total = computeOperator(values, cell.isPercentage ? "AVERAGE" : "SUM");
                        return {
                            ...cell,
                            value: total,
                            format: formatter(total, cell.isPercentage), // update formatter based on the new value
                        };
                    }

                    const value = calculatedValuesBlock.find(row => row.id === cell.id)?.values[cell.column];

                    if (cell.type === "number") {
                        values.push(value); // do not push row name (text)
                    }

                    return {
                        ...cell,
                        value: value,
                        format: formatter(value, cell.isPercentage), // update formatter based on the new value
                    };
                }),
            };
        });
    };

    useEffect(() => {
        // to update calculated values & total
        // there can be a chain of N calculated values therefore we need to have this computations inside a useEffect
        // as we do not know how many times it needs to be 'called'
        if (allItems) {
            const newData = allItems.map(item => ({
                ...item,
                values: Object.fromEntries(Object.entries(item?.values).map(([key, value]) => {
                    if (item?.external) {
                        return [key, value]; // if a cell is external, just use the DB value
                    }

                    let val;

                    // allItems computes the calculated values
                    if (item?.aggregation) { // if a value is calculated and has not yet been calculated, let's compute it
                        val = computeOperator(item?.calculated_entries.map(entryId => allItems.find(row =>
                            row.id === entryId)?.values[key]), item?.aggregation);
                    } else {
                        val = value;
                    }

                    return [key, val];
                })),
            }));

            // no need to set the state if it does not change
            if (JSON.stringify(newData) !== JSON.stringify(allItems)) {
                setAllItems(newData);
            }
        }

        if (dataReference) {
            const newData = updateBlock(dataReference, allItems);

            if (JSON.stringify(newData) !== JSON.stringify(dataReference)) {
                setDataReference(newData);
            }
        }
        if (dataActual) {
            const newData = updateBlock(dataActual, allItems);

            if (JSON.stringify(newData) !== JSON.stringify(dataActual)) {
                setDataActual(newData);
            }
        }
        if (dataDelta) {
            const newData = updateBlock(dataDelta, allItems);

            if (JSON.stringify(newData) !== JSON.stringify(dataDelta)) {
                setDataDelta(newData);
            }
        }
    }, [allItems]);

    useEffect(() => {
        client.collector.collectorGetCollector(collectorId)
            .then((collector) => {
                setCollector(collector);

                client.collector.collectorGetCollectorEntry(entryId)
                    .then((collectorEntry) => {
                        setEntry(collectorEntry);

                        client.collector.collectorListCollectorEntryItems(entryId)
                            .then((items) => {
                                setRows(items.filter(item => item?.block === "ACTUAL").map(item => ({
                                    label: item?.name,
                                    indentation: item?.indentation || 0,
                                    text_style: item?.text_style || "normal",
                                })));
                                client.collector.collectorListCollectorEntryExternalItems(entryId).then((externalItems) => {
                                    !isEmpty(externalItems) ? setExtEntriesNames([
                                        ...new Set(
                                            externalItems
                                                .map(item => item.collector_entry_name)
                                                .filter(name => hasValue(name)),
                                        ),
                                    ]) : setExtEntriesNames(null);

                                    const allItems = items.concat(externalItems);
                                    setAllItems(allItems);
                                    setDataActual(items.filter(item => item?.block === "ACTUAL").map((item, index) =>
                                        getRowCells(item, index, item?.read_only, allItems, collector, collectorEntry.permission)));
                                    setDataReference(items.filter(item => item?.block === "REFERENCE").map((item, index) =>
                                        getRowCells(item, index, item?.read_only, allItems, collector, collectorEntry.permission)));
                                    setDataDelta(items.filter(item => item?.block === "DELTA").map((item, index) =>
                                        getRowCells(item, index, true, allItems, collector, collectorEntry.permission)));
                                })
                                    .catch(() => {
                                        setAllItems(null);
                                        setDataActual(null);
                                        setDataReference(null);
                                        setDataDelta(null);
                                    });
                            })
                            .catch((error) => {
                                setRows(null);
                                notify.error(error, "collector.entry.fetch");
                            });
                    })
                    .catch((error) => {
                        setEntry(null);
                        notify.error(error, "collector.entry.fetch");
                    });
            })
            .catch((error) => {
                setCollector(null);
                notify.error(error, "collector.entries.fetch");
            });
    }, []);

    const columns = getAllMonthsBetweenDates(dayjs(collector?.start_date), dayjs(collector?.end_date));

    const formattedColumns = [
        ...columns.map(date => formatYearMonth(date, config.locale)),
        config.i18n.collector.total,
    ];

    const headerColumns = [
        { columnId: "corner", width: 315 },
        ...columns.map(col => ({ columnId: col, width: 90 })),
        { columnId: config.i18n.collector.total, width: 90 }];

    const getHeaderRow = (block) => {
        return {
            rowId: "header",
            cells: [
                { type: "header", text: block, style: { background: "#666", color: "white", fontWeight: "bold" } }, // corner indicator
                ...formattedColumns.map((col) => { // date columns
                    return {
                        type: "header",
                        text: col,
                        style: {
                            background: "#666",
                            color: "white",
                            justifyContent: "center",
                        },
                    };
                })],
        };
    };

    return (
        <AdvisorContainer
            title={entry?.name}
            loading={!dataActual || !dataReference || !dataDelta || !entry || !rows || !columns || !collector || !allItems || !headerColumns}
            loadingLabel={config.i18n.collector.loading}
            minWidth={0}
            maxWidth="xl"
            maxHeight="calc(100vh - 70px)" // Ensures the "sticky header"
        >
            {!isEmpty(extEntriesNames) ? (
                <Alert severity="info" style={{ marginBottom: 20, border: "1px solid #b3e5fc" }}>
                    <Typography variant="subtitle1" fontWeight="bold" fontSize={14}>{config.i18n.collector.alert}</Typography>
                    <ul style={{ paddingLeft: 20, marginBottom: 5, fontSize: 13 }}>
                      {extEntriesNames?.map((item, index) => (
                        <li key={index} style={{ marginBottom: 5 }}>{item}</li>
                      ))}
                    </ul>
                </Alert>
            ) : null}
            <div style={{ width: "100%", overflowX: "auto", marginBottom: 20, fontSize: 14 }}>
                <div style={{ marginBottom: 20 }}>
                    <ReactGrid
                        rows={dataActual ? [getHeaderRow(config.i18n.collector.header.forecast), ...dataActual] : []}
                        columns={headerColumns}
                        stickyLeftColumns={1}
                        stickyRightColumns={1}
                        stickyTopRows={1}
                        onCellsChanged={handleCellEdit}
                        enableRangeSelection
                    />
                </div>
                <div style={{ marginBottom: 20 }}>
                    <ReactGrid
                        rows={dataReference ? [getHeaderRow(config.i18n.collector.header.budget), ...dataReference] : []}
                        columns={headerColumns}
                        stickyLeftColumns={1}
                        stickyRightColumns={1}
                        stickyTopRows={1}
                        onCellsChanged={handleCellEdit}
                        enableRangeSelection
                    />
                </div>
                <div>
                    <ReactGrid
                        rows={dataDelta ? [getHeaderRow(config.i18n.collector.header.delta), ...dataDelta] : []}
                        columns={headerColumns}
                        stickyLeftColumns={1}
                        stickyRightColumns={1}
                        stickyTopRows={1}
                        onCellsChanged={handleCellEdit}
                        enableRangeSelection
                    />
                </div>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
                <Button
                    variant="contained"
                    component={RouterLink}
                    title={config.i18n.button.cancel}
                    color="grey"
                    style={{ minWidth: 100 }}
                    to={`/collector/${collectorId}`}
                >
                    {config.i18n.button.cancel}
                </Button>
                <Button
                    variant="contained"
                    startIcon={<SaveIcon />}
                    component={RouterLink}
                    title={config.i18n.button.save}
                    style={{ minWidth: 100 }}
                    disabled={!changed || (!isPowerUser(entry?.permission) && !isNormalUserEditing())}
                    onClick={() => saveCollectorEntry()}
                >
                    {config.i18n.button.save}
                </Button>
                {isPowerUser(entry?.permission)
                    ? (
                        <>
                            <Button
                                variant="contained"
                                startIcon={<CheckIcon />}
                                component={RouterLink}
                                title={config.i18n.button.approve}
                                disabled={changed || entry?.status === "PENDING" || entry?.status === "APPROVED"}
                                color="success"
                                style={{ minWidth: 100 }}
                                onClick={() => approveCollectorEntry()}
                            >
                                {config.i18n.button.approve}
                            </Button>
                            <Button
                                variant="contained"
                                startIcon={<CloseIcon />}
                                component={RouterLink}
                                title={config.i18n.button.reject}
                                disabled={changed || entry?.status === "PENDING" || (entry?.status !== "IN_REVIEW" && entry?.status !== "APPROVED")}
                                color="error"
                                style={{ minWidth: 100 }}
                                onClick={() => rejectCollectorEntry()}
                            >
                                {config.i18n.button.reject}
                            </Button>
                        </>
                        ) : null}
                {!isPowerUser(entry?.permission)
                    ? (
                        <Button
                            variant="contained"
                            startIcon={<CheckIcon />}
                            component={RouterLink}
                            title={config.i18n.button.submit_for_review}
                            disabled={changed || !isNormalUserEditing()}
                            color="success"
                            style={{ minWidth: 200 }}
                            onClick={() => submitForReviewCollectorEntry()}
                        >
                            {config.i18n.button.submit_for_review}
                        </Button>
                        ) : null}
            </div>
        </AdvisorContainer>
    );
};

export default CollectorEntryItemsEdit;

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formatDateTime, formatYearMonthDay } from "../../util/formatter";
import AdvisorContainer from "../../layout/AdvisorContainer";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import ClickableDataGrid from "../../components/ClickableDataGrid";
import { AppContext } from "../../AppRouter";

const CollectorEntry = () => {
    const { client, config, notify } = useContext(AppContext);
    const navigate = useNavigate();

    const [entries, setEntries] = useState();
    const [name, setName] = useState();

    const { collectorId } = useParams();

    const columns = [
        {
            field: "owner",
            headerName: config.i18n.collector.list.owner,
            flex: 2,
            sortable: false,
        },
        {
            field: "name",
            headerName: config.i18n.collector.list.name,
            flex: 6,
            sortable: false,
        },
        {
            field: "status",
            headerName: config.i18n.collector.list.status,
            flex: 3,
            sortable: false,
            renderCell: (params) => {
                let icon = null;
                let text = null;

                switch (params.value) {
                    case "PENDING":
                        icon = <CloseIcon style={{ color: "#666" }} />;
                        text = <span style={{ marginLeft: "5px", color: "#666" }}>{config.i18n.collector.status.pending}</span>;
                        break;
                    case "IN_PROGRESS":
                        icon = <SettingsIcon style={{ color: "orange" }} />;
                        text = <span style={{ marginLeft: "5px", color: "orange" }}>{config.i18n.collector.status.in_progress}</span>;
                        break;
                    case "IN_REVIEW":
                        icon = <CheckIcon style={{ color: "dodgerblue" }} />;
                        text = <span style={{ marginLeft: "5px", color: "dodgerblue" }}>{config.i18n.collector.status.in_review}</span>;
                        break;
                    case "APPROVED":
                        icon = <CheckIcon style={{ color: "green" }} />;
                        text = <span style={{ marginLeft: "5px", color: "green" }}>{config.i18n.collector.status.approved}</span>;
                        break;
                }
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {icon}
                        {text}
                    </div>
                );
            },
        },
        {
            field: "edited_by_name",
            headerName: config.i18n.collector.list.edited_by,
            flex: 2,
            sortable: false,
        },
        {
            field: "edited_at",
            headerName: config.i18n.collector.list.edited_at,
            flex: 2,
            sortable: false,
            renderCell: (params) => {
                return params.value
                    ? (
                        <span title={formatDateTime(params.value, config.locale?.language, config.locale?.timezone)}>
                            {formatYearMonthDay(params.value, config.locale?.language)}
                        </span>
                        )
                    : null;
            },
        },
        {
            field: "reviewed_by_name",
            headerName: config.i18n.collector.list.reviewed_by,
            flex: 2,
            sortable: false,
        },
        {
            field: "reviewed_at",
            headerName: config.i18n.collector.list.reviewed_at,
            flex: 2,
            sortable: false,
            renderCell: (params) => {
                return params.value
                    ? (
                        <span title={formatDateTime(params.value, config.locale?.language, config.locale?.timezone)}>
                            {formatYearMonthDay(params.value, config.locale?.language)}
                        </span>
                        )
                    : null;
            },
        },
    ];

    useEffect(() => {
        client.collector.collectorGetCollector(collectorId)
            .then((collector) => {
                setName(collector.name);
            })
            .catch((error) => {
                setEntries([]);
                notify.error(error, "collector.fetch");
            });

        client.collector.collectorListCollectorEntries(collectorId, 0, 100)
            .then((entries) => {
                setEntries(entries.response);
            })
            .catch((error) => {
                setEntries([]);
                notify.error(error, "collector.entries.fetch");
            });
    }, []);

    return (
        <AdvisorContainer
            title={name}
            loading={!entries}
            loadingLabel={config.i18n.collector.loading}
            maxWidth="xl"
        >
            <ClickableDataGrid
                pageSize={100} // quick fix to not have pagination
                rows={entries}
                columns={columns}
                hideFooterPagination
                responsive
                onRowClick={params => navigate(`/collector/${collectorId}/${params.id}`)}
            />
        </AdvisorContainer>
    );
};

export default CollectorEntry;

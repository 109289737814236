"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectorStatus = void 0;
/**
 * An enumeration.
 */
var CollectorStatus;
(function (CollectorStatus) {
    CollectorStatus["PENDING"] = "PENDING";
    CollectorStatus["IN_PROGRESS"] = "IN_PROGRESS";
    CollectorStatus["IN_REVIEW"] = "IN_REVIEW";
    CollectorStatus["APPROVED"] = "APPROVED";
})(CollectorStatus || (exports.CollectorStatus = CollectorStatus = {}));

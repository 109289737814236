import { Box, Collapse, Button, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Stack } from "@mui/system";

const DimensionBox = ({ title, children, dataCyProp, defaultClosed, collapsible }) => {
    const [open, setOpen] = useState(!defaultClosed);

    const handleCollapse = () => {
        setOpen(prev => !prev);
    };

    return (
        <Paper elevation={2} data-cy={dataCyProp}>
            <Stack direction="row" justifyContent="center" alignItems="center">
                {collapsible
                    ? (
                        <Button
                            data-cy={`${dataCyProp}-collapse-btn`}
                            disableRipple
                            endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            onClick={handleCollapse}
                            sx={{
                                "textTransform": "none",
                                "color": "text.secondary",
                                "&:hover": { // Disable hover effect
                                    backgroundColor: "transparent",
                                },
                            }}
                        >
                            <Typography variant="caption" sx={{ lineHeight: 0 /* align text with icon */ }}>
                                {title}
                            </Typography>
                        </Button>
                        )
                    : (
                        <Typography variant="caption" sx={{ my: 1, color: "text.secondary" }}>
                            {title}
                        </Typography>
                        )}
            </Stack>
            <Collapse in={open}>
                <Box margin={1}>
                    {children}
                </Box>
            </Collapse>
        </Paper>
    );
};

DimensionBox.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    dataCyProp: PropTypes.string,
    collapsible: PropTypes.bool,
    defaultClosed: PropTypes.bool,
};

export default DimensionBox;
